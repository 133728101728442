<template>
    <div class="teacher-details">
        <Header />
        <div class="content">
            <div class="title content-center">
                <CustomTitle title="资力量" head="师" titleName="师资力量" subtitle="INFORMATION" />
            </div>
            <div class="container" v-if="teacherInfo">
                <div class="teacher-content-bg"></div>
                <div class="teacher-content content-center">
                    <img class="cover1" :src="teacherInfo.cover" />
                    <img class="cover2" :src="teacherInfo.cover2" />
                    <div>
                        <div class="name-desc">
                            <div class="name">{{ teacherInfo.name }}</div>
                            <div class="desc">
                                <span>{{ teacherInfo.desc }}</span>
                            </div>
                        </div>
                        <div class="teacher-tags">
                            <div v-for="(tag, subIndex) in teacherInfo.tags" :key="subIndex" class="teacher-tag">
                                <span v-show="tag">※</span>&nbsp;{{ tag }}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="teacher-image"
                    :class="index % 2 === 1 ? 'grey-container' : ''"
                    v-for="(item, index) in teacherInfo.images"
                    :key="index"
                >
                    <img :src="item" />
                </div>
            </div>
        </div>
        <Fotter />
    </div>
</template>
<script>
import Header from '@/components/layout/Header.vue'
import Fotter from '@/components/layout/Fotter.vue'
import CustomTitle from '@/components/CustomTitle'

const teacherMap = {
    weizhen: {
        name: '韦震',
        teacherNo: 'weizhen',
        desc: '资深讲师介绍',
        cover: 'https://res.peki.vip/20210624/6a6d304043de495aaf479172d852fd57.png',
        cover2: 'https://res.peki.vip/20210623/df52ddb696384ea1afc17294ad80a703.png',
        tags: [
            '持有CATTI口译、CATTI笔译、CATTI国际版、国际人才英语考试高级、高翻笔译、高翻交传、高翻同传、上海高级口译、TESOL高级、联合国UNLPP3（笔译文化）、高等学校教师资格等证书；',
            '爱尔兰前总理外交翻译；',
            '翻译专家人才库储备人才；',
            '爱尔兰都柏林理工大学讲师；',
            '曾担任爱尔兰欧盟‘China Ready’项目讲师 ；',
            '外研社国才考试高级三星培训师；',
            '爱尔兰都柏林理工大学博士；',
            '原新东方优秀教师；',
        ],
        images: [
            'https://res.peki.vip/20210624/63798f6b275c4689ab5a35b6c37abfc3.png',
            'https://res.peki.vip/20210624/ad5df74457b1439cb0dda8b62b116dd5.png',
            'https://res.peki.vip/20210624/e48b575ade1241be9d6507bcf5ffdb65.png',
        ],
        moibleImages: [
            'https://res.peki.vip/20210706/0c47d83919c64059a1a94a198334ad19.png',
            'https://res.peki.vip/20210706/de4bfa9edf574425a321dd5ec40f6301.png',
            'https://res.peki.vip/20210706/913b0e1dc0a84524a0b1ae87cfc95804.png',
        ],
    },
    mengyan: {
        name: '孟炎',
        teacherNo: 'mengyan',
        desc: '金牌讲师介绍',
        cover: 'https://res.peki.vip/20210624/d0faa1bd89f241bdb9257faad181c01f.png',
        cover2: 'https://res.peki.vip/20210623/ecfe3c0acaad4e249340edace887ff2e.png',
        tags: [
            '持有CATTI笔译证书；',
            '密苏里大学哥伦比亚分校经济学硕士，全额奖学金；',
            '曾任新东方教研组组长，在新东方工作6年；',
            'ETS Propell Workshop认证教师；',
            '全国大学生英语竞赛特等奖；',
            '托福成绩116，多次听力口语写作满分；',
            '教育类畅销书作者；',
        ],
        images: ['https://res.peki.vip/20210624/8956e4738ae74e07a049f0947cd38f76.png'],
        moibleImages: ['https://res.peki.vip/20210706/64fb060bd5db42698cbd79d7ca257446.png'],
    },
}
export default {
    name: 'TeacherInfo',
    layout: 'headerFotter',
    components: { Header, Fotter, CustomTitle },
    data() {
        return {
            teacherInfo: null,
        }
    },
    created() {
        this.teacherInfo = teacherMap[this.$route.params.teacherNo]
        if (this.teacherInfo) {
            document.title = `${this.teacherInfo.name}-${this.teacherInfo.desc}`
        }
    },
    mounted() {
        if (!this.teacherInfo) {
            window.location.replace('/')
        }
    },
}
</script>
<style lang="scss" scoped>
.teacher-details {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    .content {
        flex-grow: 1;
    }
}
.title {
    padding-top: 24px;
}
.container {
    position: relative;
    overflow: hidden;
    .teacher-content-bg {
        position: absolute;
        top: 170px;
        left: 0;
        bottom: 0;
        width: 100%;
        background: #f8f8f8;
    }
    .teacher-content {
        position: relative;
        z-index: 1;
        border-top: 1px solid #d7d7d7;
        margin: 0 auto 52px;
        padding-top: 24px;
        display: flex;
        &::before {
            content: '';
            background: #fff;
            width: 240px;
            height: 337px;
            position: absolute;
            z-index: 1;
            left: 0;
            top: 45px;
            border-radius: 8px;
        }
        > img {
            width: 240px;
            height: 337px;
            border-radius: 8px;
            background: none;
            flex-shrink: 0;
            margin: 0 48px 0 20px;
            position: relative;
            z-index: 2;
        }
        .cover2 {
            display: none;
        }
        > div {
            padding-top: 32px;
            .name-desc {
                display: flex;
            }
            .name {
                width: 182px;
                height: 73px;
                background: $theme-color;
                position: relative;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 34px;
                font-weight: 700;
                &::before {
                    position: absolute;
                    content: 'TEACHER';
                    top: 0;
                    left: 18px;
                    transform: rotate(90deg);
                    transform-origin: left;
                    font-size: 12px;
                    font-weight: 400;
                }
            }
            .desc {
                margin-left: 14px;
                color: #000;
                font-size: 16px;
                position: relative;
                height: 20px;
                align-self: flex-end;
                margin-bottom: 4px;
                span {
                    position: relative;
                    z-index: 1;
                }
                &::after {
                    content: '';
                    position: absolute;
                    bottom: 3px;
                    left: 50%;
                    width: 80px;
                    height: 4px;
                    transform: translateX(-50%);
                    background: $theme-color;
                }
            }
        }
        .teacher-tags {
            margin-top: 32px;
            font-size: 18px;
            line-height: 1.6em;
            span {
                color: $theme-color;
                padding-right: 8px;
            }
        }
    }
}

.teacher-image {
    position: relative;
    background: #fff;
    z-index: 1;
    padding: 32px 0;
    img {
        width: $center-width;
        display: block;
        margin: 0 auto;
        background: none;
    }
}

.grey-container {
    background: #f8f8f8;
}
.content-center {
    width: $center-width;
    margin: 0 auto;
}
@media screen and (max-width: 1000px) and (min-width: 720px) {
    .title {
        display: none;
    }
    .container {
        .teacher-content-bg {
            top: 140px;
        }
        .teacher-content {
            box-sizing: border-box;
            padding: 20px;
            margin: 0;
            .teacher-tags {
                font-size: 16px;
            }
        }
    }
    .teacher-image {
        img {
            width: 100%;
        }
    }
    .content-center {
        width: 100%;
    }
}
@media screen and (max-width: 720px) {
    .title {
        display: none;
    }
    .container {
        .teacher-content-bg {
            top: 130px;
        }
        .teacher-content {
            border-top: none;
            margin: 0;
            padding-top: 32px;
            flex-direction: column;
            align-items: center;
            &::before {
                display: none;
            }
            .cover1 {
                display: none;
            }
            .cover2 {
                display: block;
                width: 184px;
                height: 184px;
                padding: 8px;
                border-radius: 50%;
                background: #fff;
                margin: 0;
            }
            > div {
                padding-top: 0px;
                margin-top: -72px;
                position: relative;
                z-index: 2;
                .name-desc {
                    flex-direction: column;
                    align-items: center;
                }
                .desc {
                    margin: 8px 0 0 0;
                    align-self: auto;
                }
            }
            .teacher-tags {
                margin: 12px;
                font-size: 14px;
                line-height: 2em;
                .teacher-tag {
                    text-align: center;
                }
            }
        }
    }
    .teacher-image {
        padding: 24px 0;
        img {
            width: 100%;
        }
    }
    .content-center {
        width: 100%;
    }
}
</style>
